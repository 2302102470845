import { useEffect, useState } from "react";
import { useFirebaseApp } from "reactfire"
import { firebaseConfig } from '../etc/Firebase'
import { doc, getDocs, getFirestore, collection, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Spinner from "../etc/Spinner";


export default function Users() {
    // useScript("https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.1/p5.min.js");
    // useScript("sketch_background.js");

    const firebase = useFirebaseApp(firebaseConfig)
    const db = getFirestore(firebase);
    const functions = getFunctions(firebase);
    // connectFunctionsEmulator(functions, "localhost", "5001")

    let navigate = useNavigate()
    let auth = getAuth();
    let [users, setUsers] = useState();
    let [usersFiltrao, setUsersFiltrao] = useState();
    let [menu, toggleMenu] = useState(false);
    let [updateMenu, toggleUpdateMenu] = useState(false);
    let [newMail, setNewMail] = useState("");
    let [newRole, setNewRole] = useState("user");
    let [loadingResponse, setLoadingResponse] = useState(false);
    let [isResponse, setIsResponse] = useState(false);
    let [queryNombres, setQueryNombres] = useState("");
    let [queryRol, setQueryRol] = useState("");

    const reportAddUser = httpsCallable(functions, 'reportAddUser');
    const deleteUser = httpsCallable(functions, 'reportDeleteUser');

    function _deleteUser(email) {
        let a = prompt("Estas seguro? Para borrar escribi DELETE")
        if (a !== "DELETE") return
        deleteUser({ email: email }).then((data) => {
            let res = data.data.res;
            if (res === "ok") {
                updateData();
            }
            else {
                alert(data.data)
                console.log(data.data)
            }
        })
    }
    function newUser() {
        toggleUpdateMenu(false);
        setNewMail("");
        setNewRole("");
        toggleMenu(true);
    }
    // function updateMenuOnClick(e, mail, role) {
    //     toggleMenu(false);
    //     setNewMail(mail);
    //     setNewRole(role);
    //     toggleUpdateMenu(true);
    // }
    // function _updateUser(e) {
    //     async function reportUpdateUser() {
    //         await updateDoc(doc(db, "report_usuarios", newMail), {
    //             role: newRole
    //         });
    //         updateData();
    //     }
    //     e.preventDefault();
    //     reportUpdateUser().then(() => {
    //         updateData();
    //         toggleUpdateMenu(false);
    //     })

    // }
    function _addNewUser(e) {
        // añadir una entrada en fs aparte de crearle la cuenta
        // e.preventDefault();
        setLoadingResponse(true);
        reportAddUser({
            email: newMail,
            role: newRole
        })
            .then((result) => {
                result = result.data;
                // console.log(result)
                if (result.error) {
                    if (result.error === "auth/email-already-exists") { alert("User already exists") }
                    else { alert(result.error) }
                } else {
                    updateData();
                    toggleMenu(false);
                    setIsResponse(result)
                }
                setLoadingResponse(false)
            })
            .catch(err => (console.log(err)));
    }

    async function getData() {
        const docRef = collection(db, "report_usuarios");
        const ownUserRef = doc(db, "report_usuarios", auth.currentUser.email);
        let _ownUserData = await getDoc(ownUserRef);
        let ownUserData = _ownUserData.data();
        let _ownrole = ownUserData.role;
        if (_ownrole !== "admin") { return navigate("/") }
        const querySnapshot = await getDocs(docRef);
        let docs = []
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id })
        });
        return docs
    }

    function updateData() {
        getData().then((data) => {
            setUsers(data);
            setUsersFiltrao(data);
        })
    }

    useEffect(() => {
        document.getElementById("display_container").style.overflow = "scroll"
        updateData()
        return () => {
            document.getElementById("display_container").style.overflow = "hidden"
        }

    }, [])

    useEffect(() => {
        filterUsuarios()
    }, [queryNombres, queryRol])


    function filterUsuarios() {

        if (queryNombres === "" && queryRol === "") { setUsersFiltrao(users); return; };
        let nuevos_usuarios = users.filter((v) => v.role == queryRol)
        setUsersFiltrao(nuevos_usuarios);
    }

    // console.log(users)
    return users && !loadingResponse ? (
        <div className="listausuarios__root">
            <div className="listausuarios__lista">

                <div >
                    {/* <input type="text" name="buscar" className="buscar_usuario"
                    placeholder="Buscar..." onChange={(e) => setQueryNombres(e.target.value)}></input> */}
                    {/* <input type="text" name="buscar_rol" className="buscar_usuario"
                    placeholder="Buscar por rol..." onChange={(e) => setQueryRol(e.target.value)}></input> */}
                    <select name="buscar_rol" id="buscar_rol" value={queryRol}
                        onChange={(e) => setQueryRol(e.target.value)}>
                        <option value="">Todos</option>
                        <option value="user">Usuario</option>
                        <option value="admin">Administrador</option>
                    </select>

                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>Mail</th>
                            <th>Rol</th>

                        </tr>
                        {usersFiltrao.map((e) => (
                            <tr key={e.id}>
                                <td>{e.id}</td>
                                <td>{e.role}</td>
                                <td>
                                    <img alt="Usuario" onClick={(event) => navigate("/users/" + e.id)}
                                        style={{ cursor: "pointer" }} width="15" height="15"
                                        src="https://www.svgrepo.com/show/146207/settings-cogwheel-button.svg">
                                    </img>
                                </td>
                                <td>
                                    <img alt="borrar usuario" onClick={(event) => _deleteUser(e.id)}
                                        style={{ cursor: "pointer" }} width="15" height="15"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqdV_TgwjyVgB6KDmAJGN3oT9XTME0sflargbMBwODuYwJA19RDXRS0aDEtDkXmZdL2aU&usqp=CAU"
                                    >
                                    </img>
                                </td>


                            </tr>
                        ))}

                    </tbody>
                </table>
                {
                    menu ?
                        <div className="listausuarios__modal">
                            <div className="listausuarios__modal_container">
                                <div className="listausuarios__modal_wrapper">
                                    <div className="listausuarios__modal_title">
                                        <h3>
                                            Agregar usuario.
                                        </h3>
                                    </div>


                                    <div className="listausuarios__modal_body">

                                        <div className="listausuarios__modal_row">
                                            <div className="listausuarios__modal_row_nombre">
                                                Mail
                                            </div>
                                            <div className="listausuarios__modal_row_input">
                                            <input type="mail" name="mail" value={newMail}
                                                onChange={(e) => setNewMail(e.target.value)}></input>

                                            </div>
                                        </div>
                                        <div className="listausuarios__modal_row">
                                            <div className="listausuarios__modal_row_nombre">
                                            <label htmlFor="role">Rol:</label>

                                            </div>
                                            <div className="listausuarios__modal_row_input listausuarios__modal_row_input_select">
                                            <select name="role" id="role" value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                                                <option value={""}></option>
                                                <option value={"user"}>user</option>
                                                <option value={"admin"}>admin</option>
                                            </select>
                                            </div>
                                        </div>


                                            {/* <input type="submit" value="Crear" className="lista"
                                            ></input> */}
                                        <div className="listausuarios__modal_buttons">
                                            <button onClick={() => { _addNewUser() }} className="listausuarios__modal_buttons_buttons listausuarios__modal_buttons_agregar">Guardar</button>
                                            <button onClick={() => toggleMenu(false)} className="listausuarios__modal_buttons_buttons listausuarios__modal_buttons_salir">Cerrar</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        : null
                }

            </div>
            {
                !menu && !updateMenu ? (
                    <div className="listausuarios__menuderecha_container">
                        <p onClick={newUser} style={{ cursor: "pointer" }}>
                            Agregar usuario
                        </p>
                    </div>)
                    : null
            }

            {/* {
                updateMenu ?
                    <div>
                        Update user
                        <form onSubmit={_updateUser}>
                            <label htmlFor="mail">Rol:</label>
                            <select name="role" id="role" value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                                <option value={""}></option>
                                <option value={"user"}>user</option>
                                <option value={"admin"}>admin</option>
                            </select>

                            <input type="submit"
                            ></input>
                        </form>
                        <a style={{ cursor: "pointer" }} onClick={() => toggleUpdateMenu(false)}>Close</a>
                    </div>
                    : null

            } */}
            {
                isResponse && !loadingResponse ?
                    <div>
                        Usuario creado satisfactoriamente.
                    </div>
                    : null
            }
        </div>

    )
        : <Spinner />
}