import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore"
import 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';

export const firebaseConfig = {
  apiKey: "AIzaSyDZYIA8fgezszOSCJ6AiAUo8BF4IYdV6Hc",
  authDomain: "digitalreport-6fcea.firebaseapp.com",
  projectId: "digitalreport-6fcea",
  storageBucket: "digitalreport-6fcea.appspot.com",
  messagingSenderId: "1087324088532",
  appId: "1:1087324088532:web:8b9a0c78d70d888f7e3341"
};

const app = initializeApp(firebaseConfig)

export const authentication = getAuth(app)
export const db = getFirestore();
export const storage = getStorage();
export const creativeRef = ref(storage, "beyondreport_files")
export default app
