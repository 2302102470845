import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { UploadFileToStorage, GetCreativeUrls } from "../etc/CreativeHelpers";
import Spinner from "../etc/Spinner";
import FormasBackground from "../etc/FormasBackground";
import { getAuth } from "firebase/auth";

export default function ReportesComponent({ data, addNewBrand }) {
    const navigate = useNavigate();
    const cookies = new Cookies()
    let [menu, toggleMenu] = useState(false);
    let [newBrandName, setNewBrandName] = useState("");
    let [newBrandColor, setNewBrandColor] = useState("#000000");
    let [logoId, setLogoId] = useState("");
    let [logoFileName, setLogoFileName] = useState("");
    let [loading, setLoading] = useState(true);
    let [urls, setUrls] = useState(null)

    let auth = getAuth();
    const newBrandMenu = () => {
        setNewBrandName("");
        setNewBrandColor("");
        toggleMenu(true);
    }

    function addFile(e) {
        setLogoFileName(e.target.value)
        let id = UploadFileToStorage(e.target.files[0]);
        setLogoId(id);
    }

    const _addNewBrand = (e) => {
        e.preventDefault();
        let newBrand = {
            name: newBrandName,
            color_primario: newBrandColor,
            // readwhitelist: [cookies.get("email")],
            // writewhitelist: [cookies.get("email")],
            logo_id: logoId,
            imagen_fondo: "",
            reportes: [],
            secciones: []
        }
        addNewBrand(newBrand).then(() => {
            toggleMenu(false);
        })
    }

    const fetchLogos = () => {
        const fetchImages = () => {
            // let _urls = {}
            let _urls = []
            let index = []
            for (let d of data) {
                if (d.logo_id) {
                    let url = GetCreativeUrls(d.logo_id);
                    // _urls[d.id] = url;
                    _urls.push(url);
                    index.push(d.id);
                }
            }
            return {_urls, index}
        }
        const loadImages = async () => {
            // console.log(fetchImages())
            let func = fetchImages()
            let ___urls = func._urls;
            let index = func.index;
            await Promise.all(___urls).then((values) => {

                let _urls = {}
                for (let i in values) {
                    _urls[index[i]] = values[i];
                }
                setUrls(_urls);
                setLoading(false);
    
            });
        }
        loadImages();

    }

    // console.log(urls)

    useEffect(() => {
        fetchLogos()
        document.getElementById("display_container").style.background = ""
        // document.getElementById("display_container").style.background = "linear-gradient(40deg, #FAB51B, transparent),linear-gradient(254deg, #FAB51B, transparent)"

        // document.getElementById("display").style["background"] = '#fafafa'
    }, []);




    return !loading ? (
        <>
            <div className="beyond_header">
                <img className="logo_beyond__header" src="/dsfull.svg" alt="Beyond Media Agency">
                </img>

                <div className="header_usuarios" >
                    <div className="header_usuarios__child" onClick={() => navigate("/users")}>
                        Usuarios
                    </div>
                    <div className="header_usuarios__child_sep">
                        |
                    </div>
                    <div className="header_usuarios__child child_weight" onClick={() => navigate("/yo")}>
                        Mi usuario
                    </div>
                </div>
            </div>

            <div className="display" style={{ justifyContent: "center",
                                              height: "108vh"}} id="display">

                <div className="reportsDiv">
                    <div className="reportsDiv__conteiner">
                        {data.map((brand) => (
                            <div key={brand.id}
                                className="reportsDiv__conteiner-brand"
                                style={{ color: brand.color_primario }}
                                onClick={() => {
                                    navigate("/brand/" + brand.id)
                                }}>

                                {brand.logo_id ?
                                    <img src={urls[brand.id]} alt={brand.name}
                                        width="250" height="50"></img>
                                    : <p style={{ cursor: "pointer" }} >{brand.name}</p>}
                            </div>
                        ))}

                    </div>
                    {
                        menu ?
                            <div className="addNewSection">
                                <form onSubmit={_addNewBrand} className="addNewSection__form">
                                    <div className="addNewSection__form-div">
                                        <label htmlFor="brandname">Nombre de la marca:</label>
                                        <input type="text" name="brandname" value={newBrandName}
                                            onChange={(e) => setNewBrandName(e.target.value)}></input>
                                    </div>
                                    <div className="addNewSection__form-div">
                                        <label htmlFor="brandcolor">Color primario:</label>
                                        <input type="color" name="brandcolor" value={newBrandColor}
                                            onChange={(e) => setNewBrandColor(e.target.value)}></input>
                                    </div>
                                    <div className="addNewSection__form-div">
                                        <label for="filelogo">Imagen de la marca (250x50px):</label>
                                        <input type="file" name="filelogo" value={logoFileName}
                                            onChange={(e) => addFile(e)} />

                                    </div>


                                    <p style={{ cursor: "pointer" }} onClick={(e) => _addNewBrand(e)}>Ok</p>

                                    {/* <input type="submit"></input> */}

                                    <p style={{ cursor: "pointer" }} onClick={() => toggleMenu(false)}>Cerrar</p>
                                </form>

                            </div>
                            : null
                    }
                </div>
                {/* <div className="footer_brands_admin">
                    <div className="box_footer_brands">
                    kjjasjkasndkjn
                    </div>
                    <div className="box_footer_brands">
                    kjjasjajsdkjasdnkasndkjn
                    </div>
                </div> */}
            </div>
            
            <div className="btn_beyond_brands_flotante">
                {
                    !menu ? (
                        <button onClick={newBrandMenu} className="buttonBeyond">
                            Añadir marca
                        </button>)
                        : null
                }

            </div>
            <FormasBackground />
        </>
    ) :
        <Spinner />
}