import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFirebaseApp } from "reactfire"
import { firebaseConfig } from '../etc/Firebase'
import { collection, doc, getDoc, getDocs, getFirestore, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { RE } from "./SingleBrand";
import Spinner from "../etc/Spinner";
import { GetCreativeUrls } from "../etc/CreativeHelpers";

export default function SingleReport() {

    const navigate = useNavigate();
    const firebase = useFirebaseApp(firebaseConfig)
    const db = getFirestore(firebase);

    let params = useParams();
    let brand_id = params.id;
    let seccion_id = params.seccion;
    let reportName = params.reportName;

    let [reportdata, setReportdata] = useState(null);
    let [reportes, setReportes] = useState(null);
    let [reportesOrdered, setReportesOrdered] = useState(null);

    let [seccion, setSeccion] = useState(null);
    let [brand, setBrand] = useState(null);
    let [logoUrl, setLogoUrl] = useState(false);
    let [menu, toggleMenu] = useState(true);

    async function getData() {
        // const docRef = doc(db, "report", id);
        // const docSnap = await getDoc(docRef);
        // return docSnap.data()

        let marcaRef = doc(db, "report", brand_id);
        let marcaData = (await getDoc(marcaRef)).data();
        let seccionRef = doc(db, "report", brand_id, "secciones", seccion_id);
        let seccionData = (await getDoc(seccionRef)).data();
        let reportesRef = collection(db, "report", brand_id, "secciones", seccion_id, "reportes");
        let reportesData = (await getDocs(reportesRef)).docs.map(s => ({ ...s.data(), fs_id: s.id }))
        // console.log(reportesData)
        return { marca: marcaData, reportes: reportesData, seccion: seccionData }
    }
    const fetchLogos = async (logo_id) => {
        const _urls = logo_id ? await GetCreativeUrls(logo_id) : null;
        return _urls
    }

    const lightenColor = function (color, percent) {
        color = color.replace("#", "")
        var num = parseInt(color, 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            B = (num >> 8 & 0x00FF) + amt,
            G = (num & 0x0000FF) + amt;

        let a = "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
        return a
    };

    function updateData() {
        getData().then((data) => {
            // let index = data.reportes.findIndex((e) => e.name === reportName)
            if (data.reportes.length === 0) { alert("La sección no contiene reportes"); navigate(-1); return; }
            setReportdata(data.reportes[0]);
            setReportes(data.reportes)
            setBrand(data.marca);
            setSeccion(data.seccion);
            fetchLogos(data.marca.logo_id).then((_urls) => {
                setLogoUrl(_urls);
            })
            if (data.seccion.order) {
                let _reportesOrdered = [...data.reportes];
                _reportesOrdered.sort((a, b) => data.seccion.order.indexOf(a.fs_id) - data.seccion.order.indexOf(b.fs_id));
                setReportes(_reportesOrdered);
    
            }
            let lighter = lightenColor(data.marca.color_primario, 80)
            let darker = lightenColor(data.marca.color_primario, -20)
            // console.log(data.marca.color_primario, lighter)
            // document.getElementById("display_container").style.background = `linear-gradient(317deg, ${darker}, ${lighter})`
            // console.log(`background: linear-gradient(317deg, "${data.color_primario}", "white")`)
        })

    }

    useEffect(() => {
        updateData()

    }, [])

    useEffect(() => {
        setReportdata(false);
        updateData();
    }, [navigate])

    let navigateRefresh = (e, name) => {

        navigate("/brand/" + brand_id + "/" + seccion_id + "/" + name);
    }
    return (
        <>
            {reportdata ?
                (

                    <div className="singleReport" >
                        <button onClick={() => navigate("/brand/" + brand_id)} className="singleReport__goBack">Atrás</button>
                        <button onClick={() => toggleMenu(!menu)} className="singleReport__menu">Menú</button>

                        <>
                            {menu ? <div className="singleReport__titles">
                                <div className="wrapper_img_report">
                                    {logoUrl ? <img className="img_responsive" src={logoUrl}></img> : <h1 id="marca_report">{brand.name}</h1>}

                                </div>

                                {/* <h3 style={{ color: "#fafafa" }}>
                                    {reportdata.name}
                                </h3> */}
                                {/* <a href={reportdata.url_datastudio} target="_blank">Link To</a> */}
                                <div className="subtitulo_report">
                                    {seccion.name}
                                </div>
                                <ul className="ul_report">
                                    {
                                        reportes.map((r) => {
                                                return (<li key={r.name} onClick={(e) => setReportdata(r)} className={reportdata.name === r.name ? "li_reporte li_reporte_current" : "li_reporte"} style={{
                                                    borderLeftColor: brand.color_primario
                                                    // textDecoration: reportdata.name === r.name ? "underline" : "none",
                                                    // backgroundColor: reportdata.name === r.name ?  : "none"
                                                }} >
                                                    <a className="lista_reportes_">{r.name}</a>
                                                    {/* style={{color: brand.color_primario}} */}
                                                </li>)
                                        })
                                    }
                                </ul>
                            </div> : null}

                            {RE.test(reportdata.url_datastudio) ?
                                (
                                    <div className="wrapper_iframe">
                                        <iframe className="iframe_report" width="600" height="450"
                                            src={reportdata.url_datastudio}
                                            frameBorder="0" style={{ border: 0 }} allowFullScreen>

                                        </iframe>
                                    </div>)
                                : "url invalida"}

                        </>
                    </div>
                )
                : <Spinner />
            }

        </>)
}